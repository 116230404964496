<template>
  <a-modal width="80%" v-model="visible" :footer="null" :title="'流水明细'" :maskClosable="false">
    <a-divider orientation="left"> 客户信息 </a-divider>
    <a-descriptions :column="3">
      <a-descriptions-item label="大区"> 华南区 </a-descriptions-item>
      <a-descriptions-item label="事务所"> 青岛事务所 </a-descriptions-item>
      <a-descriptions-item label="经销商"> 珠海总代理 </a-descriptions-item>
    </a-descriptions>
    <a-divider orientation="left"> 额度信息 </a-divider>
    <a-descriptions :column="3">
      <a-descriptions-item label="单号"> SHSKD2022063015493562629 </a-descriptions-item>
      <a-descriptions-item label="额度名称"> 四月活动 </a-descriptions-item>
      <a-descriptions-item label="额度金额"> 500.00 </a-descriptions-item>
      <a-descriptions-item label="有效时间"> 2019-04-24 </a-descriptions-item>
      <a-descriptions-item label="创建时间"> 2019-04-24 </a-descriptions-item>
      <a-descriptions-item label="创建人"> AKJERRT </a-descriptions-item>
      <a-descriptions-item label="状态" :span="3">
        <a-badge status="processing" text="使用中" />
      </a-descriptions-item>
    </a-descriptions>
    <a-divider orientation="left"> 流水信息 </a-divider>
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
      <a-row :gutter="8">
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="关联单号">
            <a-input v-model="searchData.dealNo" placeholder="请输入关联单号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="金额类型">
            <DictSelect
              field="amountType"
              :value.sync="searchData.amountType"
              style="width: 100%"
              placeholder="请选择金额状态"
            ></DictSelect>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="有效时间">
            <DateRange :startTime.sync="searchData.startDate" :endTime.sync="searchData.endDate"></DateRange>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-button type="primary" @click="getList()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :rowKey="(record) => record.id"
      bordered
      :maskClosable="false"
    >
      <span slot="SHStatus" slot-scope="text">
        <a-tag color="#ffc000" v-if="text == 1">逾期</a-tag>
        <a-tag color="#92d050" v-if="text == 2">正常</a-tag>
        <a-tag color="#ff0000" v-if="text == 3">即将到期</a-tag>
        <a-tag color="#808080" v-if="text == 4">已过期</a-tag>
      </span>
      <span slot="amountType" slot-scope="text">
        <span v-if="text == 1"><a-tag color="green"> 全部商品 </a-tag></span>
        <span v-if="text == 2"><a-tag color="cyan"> 分类商品 </a-tag></span>
        <span v-if="text == 3"><a-tag color="blue"> 指定商品 </a-tag></span>
      </span>
      <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
      <span slot="dealNo" slot-scope="text">
        <a @click="checkSingleInfo()">{{ text }}</a>
      </span>
    </a-table>
    <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
    <OrderShipInfoEditModal ref="OrderShipInfoEditModal"></OrderShipInfoEditModal>
    <div style="text-align: right; margin-top: 14px">
      <a-button type="default" @click="toCancel">关闭</a-button>
      <a-button type="primary" @click="toSubmit(tableData)">确认</a-button>
    </div>
  </a-modal>
</template>

<script>
import OrderShipInfoEditModal from '@/views/order_ship_info/components/OrderShipInfoEditModal'
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal'
const qs = require('qs')
export default {
  components: {
    OrderInfoCheckModal,
    OrderShipInfoEditModal,
  },
  data() {
    return {
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      dealerId: 0,
      title: '',
      value: 300,
      visible: false,
      columns: [
        {
          title: '交易时间',
          dataIndex: 'dealDate',
          align: 'center',
          key: 'dealDate',
        },
        {
          title: '交易前额度（元）',
          dataIndex: 'amount',
          align: 'center',
          key: 'amount',
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '交易额度（元）',
          dataIndex: 'dealAmount',
          align: 'left',
          key: 'dealAmount',
          scopedSlots: { customRender: 'dealAmount' },
        },
        {
          title: '交易后额度（元）',
          dataIndex: 'balance',
          align: 'center',
          key: 'balance',
          scopedSlots: { customRender: 'balance' },
        },
        {
          title: '交易类型',
          dataIndex: 'dealType',
          align: 'center',
          key: 'dealType',
          scopedSlots: { customRender: 'dealType' },
        },
        {
          title: '额度类型',
          dataIndex: 'amountType',
          align: 'center',
          key: 'amountType',
          scopedSlots: { customRender: 'amountType' },
        },
        {
          title: '单号',
          dataIndex: 'dealNo',
          align: 'center',
          key: 'dealNo',
          scopedSlots: { customRender: 'dealNo' },
        },
      ],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    init() {
      const _this = this
      _this.$refs.SingleUseModal.setRowData({}, 'check')
    },
    // 查询
    getList() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },

    // 重置
    reset() {
      this.page.current = 1
      this.page.pageSize = 10
      this.searchData = {}
      this.regionalOffice = []
      this.getData()
    },
    // 提交
    toSubmit(data) {
      console.log(JSON.parse(JSON.stringify(data)))
    },
    isShowByOrderCode() {
      this.visible = true
      this.getData()
    },
    toCancel() {
      this.visible = false
    },
    getData() {
      let params = {
        dealerId: this.dealerId,
        pageSize: this.page.pageSize,
        pageNumber: this.page.current,
      }
      const postData = Object.assign(params, this.searchData)
      this.axios
        .get(
          `/api/dealer/dealer/dealerPayRecord/selectPayRecordByDealer?${qs.stringify(postData, {
            arrayFormat: 'repeat',
          })}`
        )
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = [{}]
          }
        })
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-descriptions-row .ant-descriptions-item .ant-descriptions-item-label {
  width: 6.25rem;
  text-align: right;
}
</style>